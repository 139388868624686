.loader
  width: 100%
  height: 100%
  min-height: 200px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  i
    padding: 12px

$node-react-template-main-dark: #263238;
$node-react-template-main-light: #00796b;
$node-react-template-pill-in-progress: #4418c2;
$node-react-template-pill-closed: #c26a18;
$node-react-template-pill-public: #246b03;
$node-react-template-card-border: #d4d4d4;
$node-react-template-settings-header-border: #d4d4d4;
$node-react-template-dropdown-background: #fafafa;
$node-react-template-nav-button-color: #b2dfdb;
$node-react-template-editor-tool-bar-background: #1b4747;

$node-react-template-status-not-started: #fff;
$node-react-template-status-in-progress: #f6d510;
$node-react-template-status-internal-review: #14a4de;
$node-react-template-status-awaiting-external-review: #843dbc;
$node-react-template-status-external-review-open: #de8d14;
$node-react-template-status-external-review-closed: #da3b20;
$node-react-template-status-approved: #00bd39;
$node-react-template-status-creating-revision: #00eeff;
$node-react-template-status-creating-revision-error: #f80a0a;
$node-react-template-status-cloning-maps: #0077ff;

$node-react-template-modal-background-opacity: rgba($node-react-template-main-light, 0.2);
$node-react-template-lock-own: #00bd39;
$node-react-template-lock-other: #de8d14;

$node-react-template-dropdown-option-background: #80cbc4;
$node-react-template-dropdown-editor-tool-bar-selected: #00695c;
$node-react-template-map-view-button-hover: #004d40;
$node-react-template-drap-indicator: #919191;
$node-react-template-text-light: #666;
$node-react-template-text-disabled: #888;

$node-react-template-text-reverse: $node-react-template-dropdown-background;

$node-react-template-navigator-menu-background: #fafafa;
$node-react-template-navigator-menu-open: #f2f2f2;
$node-react-template-navigator-menu-active-background: rgba(0, 121, 107, 0.1);
$node-react-template-settings-menu-active-background: rgba(0, 121, 107, 0.1);
$node-react-template-navigator-menu-active-font: #004d40;

$node-react-template-structure-page-background: #fafafa;
$node-react-template-structure-chapter-background: #f2f2f2;

$node-react-template-map-preview-background-color: #e5e5e5;
$node-react-template-map-tag: #c2185b;
$node-react-template-input-character-left: #757575;
$node-react-template-form-input: #666666;
$node-react-template-user-search-item-color: #004053;
$node-react-template-user-search-item-hover: #d9e9e7;

$primary--colour--main: $node-react-template-main-light;
$primary--colour--main--dark: $node-react-template-main-dark;
$primary--colour--main--light: $aurecon--cobaltblue--300;

$pill--colour--1: $node-react-template-pill-in-progress;
$pill--colour--2: $node-react-template-pill-closed;
$pill--colour--3: $node-react-template-pill-public;
$pill--colour--4: $node-react-template-main-light;
$pill--colour--5: $node-react-template-map-tag;
$pill--colour--16: $aurecon--cobaltblue--050;

$node-react-template-status-warning: #da3b20;

$node-react-template-map-placeholder-background: #fafafa;

$node-react-template-icon-colour-opacity: rgba($node-react-template-main-light, 0.3);

$node-react-template-time-slider-max-min-date-color: #515252;
$node-react-template-time-slider-current-date-color: #6d9cce;

$node-react-template-create-new-theme-base-theme-background-color: #f2f2f2;
$node-react-template-create-new-colour-input-wrapper-background-color: #fafafa;

$node-react-template-manage-feedback-type-background-color: #f2f2f2;
$node-react-template-manage-feedback-type-border-color: #f2f2f2;
$node-react-template-manage-feedback-type-border-color-active: $node-react-template-main-light;
$node-react-template-manage-feedback-type-border-color-hover: $node-react-template-main-light;
$node-react-template-manage-feedback-link-text-color: #121212;

$node-react-template-external-feedback-field-background-color: #f2f2f2;

$node-react-template-feedback-button-disabled-color: #919191;

$node-react-template-search-content-icon-color: #00796B;
$node-react-template-search-content-input-background-active: #eee;

$node-react-template-report-setting-logo-container-text-color: #121212;
$node-react-template-report-setting-logo-container-text-highlight-color: #00796B;
$node-react-template-report-setting-logo-container-note-add-icon-color: #c4c4c4;


@import './_all_vars.sass'

.footerWrapper
  flex: 0
  width: 100%
  min-height: 40px
  border-top: 1px solid #CCD9DD
  background-color: white
  display: flex
  align-items: center
  justify-content: center

  p
    color: #121212
    text-align: center
    align-self: center

    sup
      font-size: 4px

  img
    height: 50%
    align-self: center
    margin-right: 20px

.globalFooterWrapper
  flex: 1
  width: 100%
  border-top: 1px solid #CCD9DD
  height: 1px
  padding: 24px 48px
